export const colorCodes = {
  black: ["#000", "#000"],
  white: ["#fff", "#E5E7EB"],
  green: ["#86EFAC", "#49de80"],
  purple: ["#D8B4FE", "#bf84fc"],
  blue: ["#BFDBFE", "#92c5fd"],
  red: ["#FEB4B4", "#f77171"],
  gray: ["#e5e7eb", "#d1d5db"],
} as const;

export type ViewColors = keyof typeof colorCodes;

export const bgBorderClasses = {
  black: "bg-gray-900 text-white border-black shadow-slate-800",
  white: "bg-white text-black border-gray-200 shadow-gray-100",
  green: "bg-green-300 border-green-400 shadow-green-200",
  purple: "bg-purple-300 border-purple-400 shadow-purple-200",
  blue: "bg-blue-200 border-blue-300 shadow-blue-100",
  red: "bg-red-300 border-red-400 shadow-red-200",
  gray: "bg-gray-200 border-gray-300 shadow-gray-100",
} as const;

export const hoverClasses = {
  black: "hover:bg-slate-800 group-hover:bg-slate-800",
  white: "hover:bg-gray-100 group-hover:bg-gray-100",
  green: "hover:bg-green-200 group-hover:bg-green-200",
  purple: "hover:bg-purple-200 group-hover:bg-purple-200",
  blue: "hover:bg-blue-100 group-hover:bg-blue-100",
  red: "hover:bg-red-200 group-hover:bg-red-200",
  gray: "hover:bg-gray-100 group-hover:bg-gray-100",
} as const;
