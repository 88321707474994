import { IconNames } from "./types";

import iconData from "./icons.json";
import { Pressable, View } from "../atoms/styled";
// import { Path, Svg } from "../atoms/styled";
import { ViewColors } from "../utils/colors";
import clsx from "clsx";
import { Path, Svg } from "react-native-svg";
import { cssInterop } from "nativewind";

const iconColorClasses = {
  black: "text-black",
  white: "text-white",
  green: "text-green",
  purple: "text-purple",
  blue: "text-blue",
  red: "text-red",
  gray: "text-gray",
} as const;

cssInterop(Svg, {
  className: {
    target: "style",
    nativeStyleToProp: { width: true, height: true },
  },
});

// cssInterop(Path, {
//   className: {
//     target: "style",
//     nativeStyleToProp: { fill: true, stroke: true },
//   },
// });

export default function Icon({
  icon,
  size,
  containerClassName,
  color = "black",
  className,
  onPress,
  ...props
}: React.ComponentPropsWithoutRef<typeof Svg> & {
  icon: IconNames;
  size?: number;
  containerClassName?: string;
  color?: ViewColors;
}) {
  const Child = (
    <Svg
      width={size || "18"}
      height={size || "18"}
      viewBox="0 0 18 18"
      // className="text-red-500"
      className={clsx(iconColorClasses[color], className)}
      {...props}
    >
      <Path
        d={iconData[icon].d as string}
        fill={props.fill || "currentColor"}
        stroke={
          iconData[icon].hasStroke ? props.fill || "currentColor" : "none"
        }
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );

  if (onPress)
    return (
      <Pressable onPress={onPress} className={containerClassName}>
        {Child}
      </Pressable>
    );

  return <View className={containerClassName}>{Child}</View>;
}
