import clsx from "clsx";
import { Text, TouchableOpacity, View } from "react-native";
import Loader from "./Loader";
import { useEffect, useState } from "react";
import Icon from "../icons";
import AnimatedBackgroundView from "../quarks/ColoredDiv";
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { IconNames } from "../icons/types";
import { ViewColors } from "../utils/colors";

const textVariantClasses = {
  xs: "text-xs",
  s: "text-sm font-normal",
  m: "text-base",
  l: "text-xl",
} as const;

const textColorClasses = {
  black: "text-white",
  white: "text-black",
  green: "text-black",
  purple: "text-black",
  blue: "text-black",
  red: "text-black",
  gray: "text-black",
} as const;

const iconSizeMap = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
} as const;

const iconColorMap = {
  black: "white",
  white: "black",
  green: "black",
  purple: "black",
  blue: "black",
  red: "black",
  gray: "black",
} satisfies Record<ViewColors, ViewColors>;

export default function Button({
  onPress,
  onClick,
  children,
  variant = "m",
  color = "white",
  isLoading = false,
  className,
  width,
  disabled = false,
  error,
  text,
  icon,
  style,
}: {
  onPress?: () => void;
  onClick?: (event: GestureResponderEvent) => void;
  isLoading?: boolean;
  error?: string;
  variant?: "xs" | "s" | "m" | "l";
  color?: ViewColors;
  width?: number;
  disabled?: boolean;
  children?: React.ReactNode;
  text?: string | null;
  icon?: IconNames;
  style?: StyleProp<ViewStyle>;
  className?: string;
}) {
  const [hideError, setHideError] = useState(false);
  useEffect(() => {
    if (error) {
      setHideError(false);
    }
  }, [error]);

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={(e) => {
        onPress?.();
        onClick?.(e);
      }}
      className={clsx(className, "group", {
        "cursor-not-allowed opacity-30": disabled,
      })}
      style={[styles.container, variantStyles[variant], { width }, style]}
    >
      <AnimatedBackgroundView
        color={color}
        border={variant === "m" || variant === "l"}
        style={[styles.background, variantStyles[variant]]}
      />
      {text && (
        <Text
          className={clsx(
            "relative flex flex-row items-center justify-center whitespace-nowrap text-center font-medium tracking-wider",
            textColorClasses[color],
            textVariantClasses[variant],
          )}
        >
          {!isLoading ? text : <Loader />}
        </Text>
      )}
      {icon && (
        <Icon
          icon={icon}
          size={iconSizeMap[variant]}
          color={iconColorMap[color]}
        />
      )}
      {children}
      {!hideError && error && (
        <View className="absolute top-full bg-white/75 p-1">
          <Text className="text-red-500">{error}</Text>
          <Icon icon="Close" onPress={() => setHideError(true)} />
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    position: "absolute",
  },
  overlay: {
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  button: {
    display: "flex",
  },
});

const variantStyles = StyleSheet.create({
  xs: {
    height: 22,
    borderRadius: 2,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  s: {
    height: 28,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  m: {
    height: 40,
    borderRadius: 4,
    paddingHorizontal: 16,
  },
  l: {
    height: 50,
    borderRadius: 8,
    fontSize: 16,
    paddingHorizontal: 22,
  },
});
