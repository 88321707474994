import clsx from "clsx";

import { View } from "react-native";

import { ViewColors, bgBorderClasses, hoverClasses } from "../utils/colors";

export type AnimatedBackgroundViewProps = {
  color: ViewColors;
  border?: boolean | ViewColors;
  className?: string;
  dark?: boolean;
};

export const DURATIONS = {
  SHORT: 150,
  LONG: 1000,
} as const;

export default function AnimatedBackgroundView({
  color,
  className,
  border,
  ...props
}: React.ComponentPropsWithoutRef<typeof View> & AnimatedBackgroundViewProps) {
  return (
    <View
      {...props}
      className={clsx(
        "transition-colors hover:bg-red-500",
        {
          "border-2": border,
        },
        bgBorderClasses[color],
        hoverClasses[color],
        className,
      )}
    />
  );
}
